<template>
  <div>
    <h2 class="text-center font-bold uppercase mb-4 text-2xl">
      Update Account Info
    </h2>
    <div class="flex flex-wrap mb-14 justify-start">
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="First Name"
          placeholder="First Name"
          v-model="firstName"
          data-cy="firstName"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Last Name"
          placeholder="Last Name"
          v-model="lastName"
          data-cy="lastName"
          type="text"
        />
      </div>
       <div class="w-full">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Email"
          data-cy="email"
          placeholder="Email"
          v-model="email"
          type="text"
        />
      </div>
      <div class="w-full">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Confirm Email"
          data-cy="confirm-email"
          placeholder="Confirm Email"
          v-model="confirmEmail"
          type="text"
        />
      </div>
    </div>
    <div class="text-center">
      <button
        class="mt-8"
        :disabled="loading"
        @click="updateUser"
        data-cy="submit-btn"
      >
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Update Account</span>
      </button>
    </div>
    <p class="error" v-if="formError">{{ formError }}</p>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      formError: ''
    };
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    },
    confirmEmailFields () {
      const missingEmail = (this.email === '' || this.confirmEmail === '');
      const mismatchEmail = (this.email !== this.confirmEmail);

      return missingEmail || mismatchEmail;
    }
  },
  methods: {
    resetForm () {
      this.$emit('refresh');
    },

    async updateUser () {
      const emailError = this.confirmEmailFields;
      this.formError = '';

      if (!emailError) {
        this.$store
          .dispatch('updateUserMutation', {
            input: {
              givenName: this.firstName,
              familyName: this.lastName,
              email: this.email
            }
          })
          .then(async (res) => {
            if (res.errors) {
              this.formError = res.errors[0].message;
            } else {
              this.$store.dispatch('updateUser');
              this.formError = '';
              this.resetForm();
            }
          })
          .catch(() => {
            this.formError = 'Sorry - There was an error. Please try again.';
          });
      } else {
        this.formError = 'Email and Confirm Email Must Match.';
      }
    }
  },

  created () {
    const user = this.$store.state.user;
    this.firstName = user.attributes.given_name || '';
    this.lastName = user.attributes.family_name || '';
    this.email = user.attributes.email || '';
    this.confirmEmail = user.attributes.email || '';
  }
};
</script>
