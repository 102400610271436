<template>
  <div id="user-endpoints" class="w-1/2 flex flex-wrap mb-14 justify-start">
    <div class="p-5">
      <table>
        <tr v-for="(value, name) in discoveryResponse" :key="name">
          <td>{{ camelToTitleCase(name) }}:</td>
          <td
            v-if="value"
            class="relative cursor-pointer"
            @click="copy(value, value)"
          >
            {{ value }}
            <FontAwesomeIcon class="ml-2" icon="copy" />
            <div
              v-if="showCopied === value"
              class="anim-object anim-smallcar success bg-green-500 text-white font-bold py-2 px-4 rounded-full"
            >
              Copied
              <FontAwesomeIcon class="ml-2" icon="check" />
            </div>
          </td>
          <td v-else>N/A</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import startCase from 'lodash/startCase';
import AWSConfigOptions from '@/util/AWSConfigOptions';

export default {
  data () {
    return {
      showCopied: false,
      configurations: AWSConfigOptions,
      discoveryResponse: null
    };
  },
  created () {
    this.getDiscoveryResponse();
  },
  methods: {
    copy (text, logic) {
      this.showCopied = logic;
      const copyField = document.createElement('textarea');
      document.body.appendChild(copyField);
      copyField.value = text;
      copyField.select();
      document.execCommand('copy');
      document.body.removeChild(copyField);
      this.resetCopied();
    },
    resetCopied () {
      setTimeout(() => {
        this.showCopied = false;
      }, 2000);
    },
    camelToTitleCase (str) {
      return startCase(str);
    },
    async getDiscoveryResponse () {
      // find current brand from awsOptions and ENV variables
      const brandName = localStorage.getItem('currentBrand');
      const selectedBrand = this.configurations.find(
        config => config.brandName === brandName
      );
      // make fetch request to discovery api with client_id and secret
      const res = await fetch(`https://pgdiscovery.com/api/v3/app?countryCode=${selectedBrand.locale}`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${selectedBrand.token}`
        }
      });
      const data = await res.json();
      this.discoveryResponse = data;
    }
  }
};
</script>

<style lang="scss" scoped>
.success {
  position: absolute;
  top: -21px;
  right: -29px;
  opacity: 0;
  animation: opacityOn 3s normal forwards;
}
@keyframes opacityOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
