<template>
  <div class="inline-block">
    <a class="ml-2 mr-2 cursor-pointer" @click="toggleDeleteModal">DELETE</a>

    <SimpleModal v-if="deleteModal" @close="deleteModal = false">
      <div class="text-center">
        <p class="mb-4">Are you sure you want to delete your account?</p>
        <button class="text-center" :disabled="loading" @click="deleteUser">
          <span v-if="loading">{{ loadingMessage }}</span>
          <span v-else>Do It</span>
        </button>
      </div>
    </SimpleModal>
  </div>
</template>

<script>
import SimpleModal from '@/components/UI/SimpleModal.vue';

export default {
  data () {
    return {
      deleteModal: false
    };
  },
  components: {
    SimpleModal
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    toggleDeleteModal () {
      this.deleteModal = !this.deleteModal;
    },
    deleteUser () {
      this.$store
        .dispatch('deleteUserMutation')
        .then(res => {
          if (res.data) {
            const loginPage = '/logout';
            this.$router.push({ path: loginPage });
          }
        })
        .catch(() => {
          alert('Could not delete user');
        });
    }
  }
};
</script>
