<template>
  <div class="inline-block">
    <a
      class="ml-2 mr-2 cursor-pointer"
      @click="toggleModalEdit"
      data-cy="edit-user"
      >EDIT</a
    >
    <SimpleModal v-if="editModal" @close="editModal = false">
      <EditOralBUserInfo
        v-if="
          $store.state.brand.includes('Oral-B')
        "
        @refresh="toggleModalEdit()"
      />
      <EditOpteUserInfo
        v-else-if="$store.state.brand === $store.state.brandConstants.opte"
        @refresh="toggleModalEdit()"
      />
      <EditGilletteUserInfo
        v-else-if="$store.state.brand.includes('Gillette')"
        @refresh="toggleModalEdit()"
      />
      <EditBraunUserInfo
      v-else-if="$store.state.brand.includes('Braun')"
        @refresh="toggleModalEdit()"
      />
      <EditLabsUserInfo
        v-else-if="$store.state.brand.includes('Labs')"
        @refresh="toggleModalEdit()"
      />
      <span v-else
        >Account Editing not set up for {{ $store.state.brand }}.</span
      >
    </SimpleModal>
  </div>
</template>

<script>
import SimpleModal from '@/components/UI/SimpleModal.vue';
import EditOralBUserInfo from '@/components/forms/oralB/EditOralBUserInfo.vue';
import EditOpteUserInfo from '@/components/forms/opte/EditOpteUserInfo.vue';
import EditGilletteUserInfo from '@/components/forms/gillette/EditGilletteUserInfo.vue';
import EditLabsUserInfo from '@/components/forms/labs/EditLabsUserInfo.vue';
import EditBraunUserInfo from '@/components/forms/braun/EditBraunUserInfo.vue';

export default {
  data () {
    return {
      editModal: false
    };
  },
  components: {
    EditOralBUserInfo,
    SimpleModal,
    EditOpteUserInfo,
    EditGilletteUserInfo,
    EditLabsUserInfo,
    EditBraunUserInfo
  },
  methods: {
    toggleModalEdit () {
      this.editModal = !this.editModal;
    }
  }
};
</script>
