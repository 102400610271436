<template>
  <div class="p-8 pt-20">
    <h1 class="font-bold uppercase mb-4 text-2xl" data-cy="account-info">ACCOUNT INFO</h1>
    <div id="user-details-container">
      <h3 class="text-xl inline">Details:</h3>
      <EditUser />|
      <DeleteUser />
      <UserDetails />
      <h3 class="text-xl inline">Discovery Response:</h3>
      <UserEndpoints />
    </div>
    <AppSyncData />
  </div>
</template>

<script>
import UserDetails from '@/components/user/UserDetails.vue';
import UserEndpoints from '@/components/user/UserEndpoints.vue';
import EditUser from '@/components/user/EditUser.vue';
import DeleteUser from '@/components/user/DeleteUser.vue';
import AppSyncData from '@/components/user/AppSyncData.vue';

export default {
  components: {
    UserDetails,
    UserEndpoints,
    DeleteUser,
    EditUser,
    AppSyncData
  }
};
</script>

<style lang="scss">
.vjs-tree .vjs-value__string {
  word-break: break-all;
}

#user-details td {
  padding: 5px;
}
td:first-child {
  font-weight: bold;
  font-size: 18;
}
</style>
